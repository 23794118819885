@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.category-spare {
  font-family: "Montserrat", sans-serif;
}

.cat-image {
  width: 21vw;
  border: 1px solid green;
  border-radius: 0.5rem;
}

.cat-title {
  font-size: 1rem;
  text-align: center;
  color: rgb(68, 69, 70);
  margin-top: 0;
  padding-top: 0;
}

.cat-title:hover {
  color: rgb(54, 54, 179);
}


@media only screen and (min-width: 640px) {
    .cat-image {
        width: 18vw;
    }
}

@media only screen and (min-width: 768px) {
    .cat-image {
        width: 15vw;
    }
}

@media only screen and (min-width: 1024px) {
    .cat-image {
        width: 11.3vw;
    }
}

.spare-parts-categories {
    display: grid;
    grid-template-columns: auto auto;
  }

.sub-title {
    color: rgb(107, 108, 109);
}
  
  @media only screen and (min-width: 640px) {
    .spare-parts-categories {
      grid-template-columns: auto auto auto;
    }
    .sub-title {
        font-size: 1.2rem;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .spare-parts-categories {
      grid-template-columns: auto auto auto auto;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .spare-parts-categories {
      grid-template-columns: auto auto auto auto auto;
    }
  }
  
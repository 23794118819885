.whatsapp-icon {
    color: white;
    background-color: green;
    border-radius: 1.5rem;
    font-size: 4rem;
}

.whatsapp {
    position: fixed;
    bottom: 37px;
    right: 10px;
    /* background-color: rgb(255, 242, 242); */
    border-radius: 1.5rem;
    /* padding: 0.1rem; */
}

@media only screen and (min-width: 640px) {
    .whatsapp {
        bottom: 10px;
    }
}
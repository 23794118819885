body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

html {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.header-mobile{
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 640px) {
  .navbar-desk {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .header-mobile {
    display: none;
  }
  .navbar-mobile {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .navbar-mobile {
    display: none;
  }
  .whatsapp-main {
    display: none;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.specs {
    font-family: 'Montserrat', sans-serif;
}

.specs h4 {
    margin-left: 1rem;
}

.specs-table {
    margin-left: 2rem;
}

.product-td {
    color: #878787;
    padding-right: 5rem;
}
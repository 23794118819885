@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.product {
    font-family: 'Montserrat', sans-serif;
}

.image {
    width: 45vw;
}

.Title {
    text-align: center;
    font-size: 1.1rem;
    color: rgb(46, 45, 45);
}

.Title:hover {
    color: rgb(51, 51, 243);
}

.price {
    margin-top: 0;
    text-align: center;
    font-size: 1.1rem;
    color: rgb(46, 45, 45);

}

.price del {
    margin-top: 0;
    font-size: 0.9rem;
    margin-left: 0.5rem;
    color: rgb(122, 124, 126);
}

.price strong {
    margin-top: 0;
    color: rgb(93, 138, 26);
    font-size: 1rem;
}

@media only screen and (max-width: 640px) {

}

@media only screen and (min-width: 640px) {
    .image {
        width: 30vw;
    }
    
}

@media only screen and (min-width: 768px) {
    .image {
        width: 22.5vw;
    }
    
}

@media only screen and (min-width: 1024px) {
    .image {
        width: 18vw;
    }
    
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.hero-icon {
    display: flex;
    justify-content: space-around;
    color: orange;
    align-items: center;
}

.hero-icon h5 {
    text-align: center;
    font-weight: 900;
}

@media only screen and (max-width: 324px) {
    .hero-icon h5 {
        font-size: 11px;
    }
}

.hero-desk {
    display: flex;
    /* justify-content: end; */
}

/* .hero-desk img {
    width: 98.7vw;
    text-align: center;
} */
.hero-mobile img {
    width: 99vw;
}

/* .hero-desk h1{
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 3.3rem;
    margin-right: auto;
    margin-left: auto;
} */

@media only screen and (max-width: 640px) {
    .hero-desktop {
        display: none;
    }
}

@media only screen and (min-width: 640px) {
    .hero-mobile {
        display: none;
    }
    .hero-desktop img {
        width: 98.3vw;
    }
}

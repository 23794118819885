@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.header {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
  height: 55px;
  /* margin-top: 1rem; */
  font-family: 'Roboto', sans-serif;
}

.number {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  font-weight: 500;
  padding-top: 0.5rem;
}

.logo {
  padding-top: 0.5rem;
  font-weight: normal;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    color: white;
    /* padding: 0 0.5rem; */
    position: fixed;
    bottom: 0;
    width: 100vw;
}

.navbar h4 {
    margin: 0rem;
    padding-top:0.25rem ;
    padding-bottom:0.25rem ;
}

.navbar-last {
    padding-right: 2rem;
}
.categories {
  display: grid;
  grid-template-columns: auto auto;
}

@media only screen and (min-width: 640px) {
  .categories {
    grid-template-columns: auto auto auto;
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 1024px) {
  .categories {
    grid-template-columns: auto auto auto auto;
  }
}

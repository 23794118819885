.view-all {
    display: flex;
    justify-content: space-between;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

.name {
    color: rgb(96, 97, 97);
    padding-left: 15px;
    font-weight: bolder;
}

.view {
    color: white;
    font-size: 0.9rem;
    border: 1px solid black;
    padding: 0.5px 10px;
    border-radius: 5px;
    background-color: rgb(4, 87, 182);
    margin-right: 15px;
}

.view:hover {
    color: rgb(248, 189, 79);
}
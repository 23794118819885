@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


.products-category {
  font-family: 'Montserrat', sans-serif;
}

.img {
  width: 32vw;
}

.title {
  font-size: 1.1rem;
  text-align: center;
  color: rgb(29, 28, 28);
}

.title:hover {
  color: blue;
}


@media only screen and (max-width: 640px) {
}

@media only screen and (min-width: 640px) {
  .img {
    width: 23vw;
  }
}
@media only screen and (min-width: 768px) {
  .img {
    width: 18vw;
  }
}

@media only screen and (min-width: 1024px) {
  .img {
    width: 15vw;
  }
}

.footer-head {
    /* width: 100vw; */
    background-color: black;
    color: white;
    padding: 0.2rem 0rem 2rem 1rem;
}

a {
    text-decoration: none;
    color: white;
    font-size: 1.1rem;
}

.footer {
    /* width: 100vw; */
    display: flex;
    justify-content: space-between;
} 

.footer-main {
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;
}

.footer-icons {
    display: flex;
    column-gap: 2rem;
}

.footer-navs {
    display: flex;
    flex-direction: column;
    padding-right: 3rem;
    row-gap: 0.4rem;
}

h3 {
    text-align: center;
}

.mui-icons:hover {
    color: rgb(35, 203, 233);
}
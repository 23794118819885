body {
  margin: 0;
  padding: 0;
}

/* .home-hero{
  margin: 0;
  padding: 0;
  border: 1px solid black;
} */

.product-category {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-evenly;
}

.products {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  column-gap: 0.4rem;
}

/* .products-list {
    width: 44vw;
} */

.spare-list {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  column-gap: 0.4rem;
  width: 22vw;
  row-gap: 0.4rem;
}

.number-specific {
  width: 98.7vw;
}

.parts-product {
  display: grid;
  grid-template-columns: auto auto;
}

@media only screen and (max-width: 640px) {
  /* .home-desk {
    display: none;
  } */
  .number-specific {
    height: 150px;
  }
  .hide {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  /* .home-hero {
    display: none;
  } */
  .product-category {
    grid-template-columns: auto auto auto auto;
  }
  .products {
    grid-template-columns: auto auto auto;
  }
  .spare-list {
    grid-template-columns: auto auto auto auto auto ;
  }
  .parts-product {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px)  {
  .spare-list {
    grid-template-columns: auto auto auto auto auto auto;
  }
  .product-category {
    grid-template-columns: auto auto auto auto auto;
  }
  .parts-product {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }
  .products-motor {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }
  .hide {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .product-category {
    grid-template-columns: auto auto auto auto auto auto;
  }
  .products {
    grid-template-columns: auto auto auto auto;
  }
  .spare-list {
    grid-template-columns: auto auto auto auto auto auto auto auto;
  }
  .parts-product {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
  }
  .products-motor {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
  }
  .hide-spare {
    display: none;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.nav-desk {
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
  font-family: "Roboto", sans-serif;
  align-items: center;
  height: 70px;
}

.nav-desk nav {
  display: flex;
  column-gap: 1rem;
  padding-top: 0.6rem;
}

.nav-desk-h3:hover {
  color: orange;
}

.nav-desk-h3 {
  font-weight: 500;
}

.nav-desk-logo {
  font-weight: 550;
  font-size: 2.3rem;
}

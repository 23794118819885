@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.product-page {
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: 'Montserrat', sans-serif;
    
}

img {
    width: 90vw;
    
}

.main-title {
    text-align: center;
    font-size: 1.7rem;
    font-weight: 900;
}

.pricing {
   display: flex;
   justify-content: center;
   column-gap: 0.7rem;
   align-items: center;
   font-weight: 900;
}

.del-amount {
    font-size: 1rem;
    color: rgb(104, 109, 109);

}

.discount {
    font-size: 1.2rem;
    color: green;
}

.buy {
    display: inline-block;
    padding: 0.1rem 0.5rem;
    border: 1px solid black;
    border-radius: 1rem;
    background-color: black;
    color: white;
    font-size: 1.2rem;
    margin-top: 0.4rem;
    margin-right: 0.4rem;
    font-weight: 700;
}

.icons {
    display: flex;
    justify-content: space-around;
    color: orange;
    align-items: center;
}

.icons h5 {
    text-align: center;
    font-weight: 900;
}

.contents p {
    margin-left: 2rem;
}

.key-features p {
    margin-left: 2rem;
}


.highlights-h1 {
    font-size: 1rem;
}



@media only screen and (min-width: 768px) {
    .product-page-desk {
        display: flex;
        justify-content: space-between;
        column-gap: 1.5rem;
    }
    .image-desk {
        width: 45vw;
    }

    .product-page-desk-info {
        margin-top: auto;
        margin-bottom: auto;
    }
}
